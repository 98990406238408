.blog-post {
    color: #f2f2f2;
    padding-top: 140px;
    margin: 0 auto;

    max-width: 800px;
    text-align: center;

    a {
        color: #aaa;
    }

    .title {
        h1 {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
        }
        span {
            color: #aaa;
        }
        margin-bottom: 20px;
    }

    hr {
        width: 100px;
        margin: 0 auto;
        background-color: #FFF;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .blog-post-content {
        text-align: justify;
        font-family: 'AcherusGrotesque';

        // start unsure
        padding: 40px;
        // end unsure
        
        h1, h2, h3 {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
        }

        h2 {
            font-size: 25px;
            margin-top: 30px;
        }

        strong {
            font-size: 18px;
        }
    }
}

.blog-list {
    color: #f2f2f2;
    padding-top: 140px;
    margin: 0 auto;

    max-width: 800px;
    text-align: center;

    .text-holder {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        max-width: 600px;
        margin-bottom: 30px;

        .attached-letters {
            font-family: 'Heritage';
            font-size: 40px;
            margin-bottom: -20px;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-weight: bold;
            font-size: 50px;
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            font-size: 20px;
            margin-top: 0;
        }

        hr {
            width: 20px;
            background-color: #FFF;
            height: 2px;
            margin-left: 0 auto;
        }
    }

    .blog-listing {
        display: flex;
        flex-direction: column;
        .blog-list-item {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 20px;
            a.blog-title {
                font-family: 'OdudoSlab';
                text-transform: uppercase;
                color: #FFF;
                font-size: 25px;
            }

            span.blog-date {
                font-size: 12px;
                color: #aaa;
            }

            div.blog-preview {
                font-size: 14px;
                color: #d3d3d3;

                p {
                    margin-bottom: 0;
                }

                a {
                    color: #FFF;
                }
            }
        }
    }
}