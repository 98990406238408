/* Highlights and titles */
@font-face {
    font-family: OdudoSlab;
    src: url(/fonts/odudo_slab/OdudoSlab-Regular.otf);
}

@font-face {
    font-family: OdudoSlab;
    src: url(/fonts/odudo_slab/OdudoSlab-SemiBold.otf);
    font-weight: bold;
}

/* Not very readable */
@font-face {
    font-family: Heritage;
    src: url(/fonts/heritage/Heritage-Regular.otf);
}

/* Main font */
@font-face {
    font-family: AcherusGrotesque;
    src: url(/fonts/acherus_grotesque/AcherusGrotesque-Regular.otf);
}

@font-face {
    font-family: AcherusGrotesque;
    src: url(/fonts/acherus_grotesque/AcherusGrotesque-Bold.otf);
    font-weight: bold;
}

body {
    background-color: #000;
    position: relative;
    overflow-x: hidden;
}

#main-menu {
    a {
        font-family: 'AcherusGrotesque';
        color: #FFF;
    }

    min-height: 90px;
    position: fixed;
    z-index: 99;
    width: 100%;

    .toggler {
        min-height: 90px;
        background-color: black;
        display: flex;
        justify-content: space-between;

        .mobile-logo {
            display: flex;
            align-items: center;

            img {
                padding-left: 20px;
            }
        }

        .toggler-button {
            outline: none;
            background: none;
            border: none;
            padding: 0 20px;

            .fa-bars {
                color: white;
                height: 40px;
                width: 40px;
                font-size: 30px;
            }
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    .side-menu {
        background-color: black;
        display: none;

        max-width: 400px;
        min-width: 250px;

        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 10;

        @include media-breakpoint-up(xl) {
            display: none !important;
        }

        a {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;

        }

        .logo {
            text-align: center;
            img {
                padding: 25px 20px;
                max-width: 200px;
            }
            margin-bottom: 30px;

            font-size: 14px;
            letter-spacing: 2px;
        }

        hr {
            height: 1px;
            background-color: #FFF;
            width: 40px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;

            li {
                padding-bottom: 18px;
            }
        }
    }

    .inline-menu {
        background-color: black;
        display:none;
        @include media-breakpoint-up(xl) {
            display: flex;
        }

        padding: 10px 0;

        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .menu-holder {
            margin-right: 50px;
        }

        .change-language {
            font-size: 11px;
            color: #777;
            line-height: 40px;
            margin-left: 20px;
            a {
                color: #FFF;
            }
        }

        a.nav-link {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;

            font-size: 13px;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                letter-spacing: 2px;
            }

            border-bottom: 1px solid #000;

            transition: border-color 300ms;

            &:hover, &.active {
                border-color: #FFF;
            }
        }

        .logo {
            max-height: 30px;
            margin: 10px 50px;

            @include media-breakpoint-up(lg) {
                max-height: 50px;
            }
        }
    }
}

.light-button {
    border: #FFF;
    background: #FFF;
    border-radius: 1px;
    padding: 10px 15px;
    font-size: 18px;
    text-transform: uppercase;
    outline: none;
    color:#000 !important;
}

.page {
    padding-top: 50px;
    padding-bottom: 50px;
}

footer {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    flex-direction: column;

    color: #FFF;
    font-family: 'AcherusGrotesque';

    .media {
        a {
            color: #FFF;
            text-decoration: none;
        }
        .circle {
            border-radius: 50%;
            border: 2px solid #FFF;
            padding: 10px;

            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            margin: 0 5px;

            i {
                font-size: 25px;
            }

            transition: background-color 200ms;

            &:hover {
                background-color: #FFF;
                color: #000;
            }
        }
    }

    .policy  {
        color: #aaa;
        margin-top: 30px;
        font-size: 12px;
        a {
            text-transform: uppercase;
        }
    }

    hr {
        width: 20px;
        margin: 0 auto;
        background-color: #aaa;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .copyright {
        color: #aaa;
        text-transform: uppercase;
        font-size: 12px;
    }
}