.page.contact {
    color: #f2f2f2;
    padding-top: 140px;
    margin: 0 auto;

    max-width: 1180px;
    text-align: center;

    a {
        color: #aaa;
    }

    .text-holder {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        max-width: 600px;
        margin-bottom: 90px;

        .attached-letters {
            font-family: 'Heritage';
            font-size: 40px;
            margin-bottom: -20px;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-weight: bold;
            font-size: 50px;
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            font-size: 20px;
            margin-top: 0;
        }

        hr {
            width: 20px;
            background-color: #FFF;
            height: 2px;
            margin-left: 0 auto;
        }
    }

    #contact-toast {
        width: 100%;
        background-color: #444;
        color: #f2f2f2;
        margin-top: 20px;
        max-width: none;

        .toast-body {
            width: 100%;
        }
    }

    #contact {
        min-width: 400px;
        max-width: 600px;
        margin: 0 auto;
    }

    .modal {
        font-family: 'AcherusGrotesque';

        color: #222;
        label {
            color: #222;
        }

        h4 {
            text-align: left;
            margin-bottom: 0;
            font-size: 20px;
        }

        input, textarea, select {
            font-family: 'AcherusGrotesque';
            margin-bottom: 20px;

            background-color: #f2f2f2;
            border: 1px solid #444;
            color: #222;

            outline-color: #444;
        }

        hr {
            margin-top: 0;
            background-color: #444;
            width: 100%;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        label {
            font-family: 'AcherusGrotesque';
            text-align: left;
        }

        input[type=number] {
            width: 50px;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button {  
            opacity: 1;
        }
        input {
            padding: 2px 5px;
        }

        input, textarea, select {
            font-family: 'AcherusGrotesque';
            margin-bottom: 20px;

            background-color: #222;
            border: 1px solid #444;
            color: #f2f2f2;

            outline-color: #444;
        }
    }

    .order-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        max-width: 600px;
        margin-bottom: 50px;
        padding: 20px;

        .attached-letters {
            font-family: 'Heritage';
            font-size: 40px;
            margin-bottom: 0;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-weight: bold;
            font-size: 30px;
            margin-bottom: 10px;
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            font-size: 16px;
            margin-top: 0;
        }

        .light-button {
            margin-top: 15px;
        }

        hr {
            width: 20px;
            background-color: #FFF;
            height: 2px;
            margin-left: 0 auto;
        }
    }
}