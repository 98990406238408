.product-page {
    .bbq-hero {
        background-image: url('/img/bbq_cover.jpg');
        background-position: 50% 50%;
        max-width: none;
        min-height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        margin-top: 100px;
    }

    .bbq-products {
        .col-lg-4 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 40px;
        }
        img {
            max-width: 300px;
        }
    }

    .text-holder {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 40px;
        margin-bottom: 20px;

        color: #FFF;

        &.product-sausage,
        &.product-salami {
            margin-bottom: 50px;
        }

        .attached-letters {
            font-family: 'Heritage';
            font-size: 40px;
            margin-bottom: -15px;
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            text-transform: uppercase;
            font-size: 18px;
            letter-spacing: 3px;
        }

        .smaller-bold-text {
            font-family: 'AcherusGrotesque';
            text-transform: uppercase;
            font-size: 26px;
            letter-spacing: 3px;
            font-weight: bold;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-size: 50px;
        }
    }
}



.products {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-of-type {
        margin-bottom: 100px;
    }

    hr {
        width: 500px;
        margin: 0 auto;
        background-color: #FFF;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .product-carousel {
        $carousel-width: 400px;

        margin: 0 auto;
        height: 500px;
        width: $carousel-width;

        &.product-classic {
            height: 400px;

            .product-cover {
                .product-cover-holder {
                    height: 400px;

                    img {
                        max-width: $carousel-width;
                    }
                }
            }

            .product-description {
                height: 400px;

                .product-description-holder {
                    height: 400px;
                }
            }
        }

        &.product-rillettes {
            height: 400px;

            .product-cover {
                .product-cover-holder {
                    height: 400px;

                    img {
                        max-width: $carousel-width;
                    }
                }
            }

            .product-description {
                height: 400px;

                .product-description-holder {
                    height: 400px;
                }
            }
        }

        &.product-sausage {
            .product-cover {
                .product-cover-holder {
                    img {
                        max-height: 450px;
                    }
                }
            }
        }

        &.product-pie {
            .product-cover-holder {
                img {
                    max-width: 100%;
                }
            }
        }

        .product-cover {
            .product-cover-holder {
                height: $carousel-width;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            img {
                margin: 0 auto;
                outline: none;
            }

            outline: none;
        }

        @include media-breakpoint-down(md) {
            .slick-arrow {
                display: none !important;
            }
        }

        .slick-arrow {
            border: none;
            cursor: pointer;
            outline: none;

            transition: background-color .3s ease-in;

            &.slick-next {
                position: absolute;
                right: 0;
                top: 50%;
                height: 50px;
                width: 50px;
                background: transparent;

                &:after {
                    position: absolute;
                    right: 20px;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: '\f054';
                    color: #FFF;
                    opacity: 0;
                    transition: opacity .3s ease-in;
                    top: 14px;
                }
            }

            &.slick-prev {
                position: absolute;
                left: 0;
                top: 50%;
                height: 50px;
                width: 50px;
                background: transparent;
                z-index: 1;

                &:before {
                    position: absolute;
                    left: 20px;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: '\f053';
                    color: #FFF;
                    opacity: 0;
                    transition: opacity .3s ease-in;
                    top: 14px;
                }
            }
        }

        &:hover {
            .slick-arrow {
                //background-color: rgba(255,255,255,0.1);
            }

            .slick-arrow.slick-prev:before {
                opacity: 1;
            }

            .slick-arrow.slick-next:after {
                opacity: 1;
            }
        }

        .product-description {
            height: $carousel-width;
            width: $carousel-width;
            outline: none;

            .product-description-holder {
                height: $carousel-width;
                width: $carousel-width;
                padding: 0 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-down(md) {
                    padding: 0 20px;
                }
            }

            hr {
                width: 20px;
                margin: 0 auto;
                background-color: #FFF;
                height: 2px;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .product-name {
                font-family: 'OdudoSlab';
                text-transform: uppercase;
                font-size: 40px;
            }

            .product-flavor {
                font-family: 'AcherusGrotesque';
                font-size: 18px;
                margin-bottom: 10px;
                margin-top: 10px;
                color: #FFF;

                text-align: center;
            }

            .product-ingredients {
                margin-top: 10px;
                text-align: center;
                font-size: 14px;
                color: #a9a9a9;
            }
        }

        .product-nutrition {
            height: $carousel-width;
            width: $carousel-width;
            outline: none;

            .product-nutrition-holder {
                height: $carousel-width;
                width: $carousel-width;
                padding: 0 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                padding-bottom: 15px;

                @include media-breakpoint-down(md) {
                    padding: 0 20px;
                }
            }

            color: #fff;

            hr {
                width: 20px;
                margin: 0 auto;
                background-color: #FFF;
                height: 2px;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            .packaging-size {
                margin-bottom: 0px;
                font-size: 14px;
                font-family: 'AcherusGrotesque';
            }

            .nutrition-fact {
                font-family: 'AcherusGrotesque';
                text-transform: uppercase;
                font-weight: bolder;
                letter-spacing: 1px;
                font-size: 20px;
                margin-top: 10px;
            }

            .nutrition-value {
                letter-spacing: 2px;
                font-size: 16px;
            }
        }

        &.product-sausage,
        &.product-salami {
            .slick-dots {
                margin-top: 30px;
            }
        }

        &.product-cartridge {
            .slick-dots {
                margin-top: 10px;
            }
        }

        .slick-dots {
            display: flex;
            justify-content: center;

            margin: 0;
            padding: 5px 0;
            margin-top: 10px;

            list-style-type: none;

            li {
                margin: 0 0.25rem;
            }

            button {

                display: block;
                width: 5px;
                height: 5px;
                padding: 0;

                border: none;
                border-radius: 100%;
                background-color: rgba(255, 255, 255, 0.7);

                text-indent: -9999px;
            }

            li.slick-active button {
                background-color: white;
            }

        }
    }
}