.locations {
    padding-top: 140px;

    .locations-header {
        justify-content: center;
        display: flex;
        padding: 10px;
    }

    .delivery-header {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 10px;

        margin-top: 60px;

        .text-holder {
            .smaller-text {
                font-size: 18px;
            }
        }
    }

    .text-holder {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #fff;
        max-width: 600px;

        .attached-letters {
            font-family: 'Heritage';
            font-size: 40px;
            margin-bottom: -20px;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-weight: bold;
            font-size: 50px;
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            font-size: 20px;
            margin-top: 0;
        }

        hr {
            width: 20px;
            background-color: #FFF;
            height: 2px;
            margin-left: 0 auto;
        }
    }

    .mapboxgl-ctrl-geocoder {
        border: 0;
        border-radius: 0;
        position: relative;
        top: 0;
        width: 800px;
        margin-top: 0;
    }
      
    .mapboxgl-ctrl-geocoder > div {
        min-width: 100%;
        margin-left: 0;
    }
    
    .location-finder {
        margin: 0 auto;
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        max-width: 1180px;
    
        .locations-list {
            flex-basis: 30%;
            display: flex;
            flex-direction: column;
            max-height: 600px;
            overflow-y: auto;
            overflow-x: hidden;
            margin-right: 10px;
    
            background-color: #303030;

            @include media-breakpoint-down(md) {
                display: none;
            }
    
            .item {
                display: block;
                flex-direction: column;
                padding: 10px;
                color: #f2f2f2;
    
                border-bottom: 1px solid #f2f2f2;
    
                a.address {
                    color: #fff;
                    font-size: 14px;
                    font-family: AcherusGrotesque;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
    
                .name {
                    //font-weight: bold;
                    text-transform: uppercase;
                    font-family: OdudoSlab;
                }
            }
        }
        #map {
            flex-basis: 70%;

            @include media-breakpoint-down(md) {
                flex-basis: 100%;
            }

            .marker {
                border: none;
                cursor: pointer;
                height: 40px;
                width: 26px;
                background-image: url(/img/marker.png);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
              }
        }
    }
    
    .mapboxgl-popup-content {
        font-family: AcherusGrotesque;
        padding: 0;
        width: 250px;
    
        .store-popup {
            display: flex;
            flex-direction: column;
            padding: 10px;
    
            .name {
                font-weight: bold;
                font-size: 18px;
            }
        }
      }
      
      .mapboxgl-popup-content-wrapper {
        padding: 1%;
      }

      .delivery-map {
          padding: 25px 50px;

          img {
            max-width: 80%;
            margin: 0 auto;
            display: flex;
          }
      }
}