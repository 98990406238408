.about {
    margin: 0 auto;
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-holder {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #fff;
        max-width: 600px;
        margin-bottom: 30px;

        .attached-letters {
            font-family: 'Heritage';
            font-size: 40px;
            margin-bottom: 0;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-weight: bold;
            font-size: 50px;
            margin-top: -20px;
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            font-size: 20px;
            margin-top: 0;
        }

        hr {
            width: 20px;
            background-color: #FFF;
            height: 2px;
            margin-left: 0 auto;
        }
    }

    .story {
        text-align: center;
        color: #f2f2f2;
        padding: 20px;
        max-width: 800px;
    }

    .portrait {
        width: 100%;
        height: 600px;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/img/portrait.jpg');
        background-position: 50% 0%;

        margin-bottom: 30px;
        margin-top: 40px;
    }

    .signature {
        img {
            max-width: 300px;
        }
    }

    .values {
        padding: 20px;
        color: #f2f2f2;
        text-align: center;
        max-width: 800px;
        font-family: 'AcherusGrotesque';

        h3 {
            font-family: 'OdudoSlab';
            margin-bottom: 30px;
            margin-top: 20px;
        }
    }
    

    hr {

    }
}