%cover-base {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;

    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &:before {
        background-color: #000;
        background-size: cover;
        content: '';
        height: 100%;
        left: 0px;
        opacity: 0;
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: -1;
        -webkit-transition: opacity 300ms;
        transition: opacity 300ms;
    }

    /* &:hover:before{
        opacity: 0.7;
    } */
}

.home-cover {
    @extend %cover-base;
    background-image: url('/img/pork_cover2_c.jpg');

    @media only screen and (min-width: 1600px) {
        background-image: url('/img/pork_cover2.jpg');
    }
    
    height: 100vh;
    background-position: 50% 70%;

    @include media-breakpoint-down(sm) {
        background-image: url('/img/pork_cover_mobile.jpg');
        background-position: 50% 50%;
    }

    align-items: center;
    padding-top: 90px;

    .text-holder {
        display: flex;
        flex-direction: column;
        
        max-width: 800px;
        text-align: center;
        padding: 40px;

        color: #FFF;

        .quebec {
            max-width: 30px;
            margin: 30px auto;
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            text-transform: uppercase;
            font-size: 20px;
            margin-bottom: -5px;
            margin-top: 0;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-size: 55px;
            margin-top: -20px;
            font-weight: bold;

            @include media-breakpoint-down(md) {
                font-size: 40px;
            }
        }

        .attached-letters {
            font-family: 'Heritage';
            font-size: 62px;
            margin-bottom: -15px;

            @include media-breakpoint-down(md) {
                font-size: 50px;
            }
        }

        hr {
            width: 20px;
            margin: 0 auto;
            background-color: #FFF;
            height: 2px;
            margin-top: 20px;
        }
    }

    .call-to-action {
        
    }

    .scroll-down {
        position: absolute;
        color: #FFF;
        bottom: 20px;
        font-size: 50px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.products-cover {
    position: relative;
    background-color: #000;
    height: 500px;
    display: flex;
    justify-content: left;
    z-index: 1;

    background-repeat: no-repeat;
    background-position: left;
    background-image: url('/img/jack_cover.png');
    background-size: auto 500px;

    background-position-y: 100%;
    background-position-x: -10%;

    @media only screen and (min-width: 1800px) {
        background-position: 5% 90%;
        background-image: url('/img/products_trio.jpg');
    }

    @media only screen and (min-width: 1600px) and (max-width: 1799px) {
        padding-top: 20px;
        background-position: 0% 90%;
        background-image: url('/img/products_trio.jpg');
    }

    @include media-breakpoint-down(md) {
        height: 600px;
        background-position: -30% 100%;
    }

    @include media-breakpoint-down(sm) {
        background: none;
        justify-content: center;
    }

    .text-holder {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        padding: 40px;

        color: #FFF;

        margin-left: 300px;
        margin-right: auto;

        @include media-breakpoint-down(sm) {
            text-align: center;
            margin: 0;
            max-width: none;
        }

        @media only screen and (min-width: 1800px) {
            margin-left: 600px;
        }

        @media only screen and (min-width: 1600px) and (max-width: 1799px) {
            margin-left: 500px;
        }

        .attached-letters {
            font-family: 'Heritage';
            font-size: 50px;
            margin-bottom: -15px;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-size: 55px;
            font-weight: bold;

            @include media-breakpoint-down(lg) {
                font-size: 50px;
            }

            @include media-breakpoint-down(md) {
                font-size: 40px;
            }
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            font-size: 18px;
            margin-bottom: 10px;
            margin-top: 10px;
            max-width: 600px;
        }

        hr {
            width: 20px;
            background-color: #FFF;
            height: 2px;
            margin-left: 0;

            @include media-breakpoint-down(sm) {
                text-align: center;
                margin: 10px auto;
            }
        }
    }
}

.products-mobile {
    @extend %cover-base;
    background-image: url('/img/jack_cover.png');
    margin-top: 30px;
    background-position-y: 0%;
    background-size: cover;
    height: 450px;
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.values-cover {
    @extend %cover-base;
    background-image: url('/img/concrete_cover.jpg');

    background-position-y: 100%;

    .text-holder {
        color: #000;
        text-align: center;
        outline: none;

        .smaller-text {
            font-family: 'AcherusGrotesque';
            text-transform: uppercase;
            font-size: 30px;
            
            margin-top: 0;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-size: 60px;
            font-weight: bold;

            @include media-breakpoint-down(md) {
                font-size: 40px;
            }
        }
    }

    .values-carousel {
        height: 300px;
        margin: 0 auto;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(md) {
            .slick-arrow {
                display: none !important;
            }
        }

        .slick-arrow {
            border: none;
            cursor: pointer;
            outline: none;

            transition: background-color .3s ease-in;

            &.slick-next {
                position: absolute;
                right: 0;
                top: 50%;
                height: 50px;
                width: 50px;
                background: transparent;

                &:after {
                    position: absolute;
                    right: 20px;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: '\f054';
                    color: #000;
                    opacity: 0;
                    transition: opacity .3s ease-in;
                    top: 14px;
                }
            }

            &.slick-prev {
                position: absolute;
                left: 0;
                top: 50%;
                height: 50px;
                width: 50px;
                background: transparent;
                z-index: 1;

                &:before {
                    position: absolute;
                    left: 20px;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: '\f053';
                    color: #000;
                    opacity: 0;
                    transition: opacity .3s ease-in;
                    top: 14px;
                }
            }
        }

        &:hover {
            .slick-arrow {
                background-color: rgba(255,255,255,0.1);
            }
            .slick-arrow.slick-prev:before {
                opacity: 1;
            }
            .slick-arrow.slick-next:after{
                opacity: 1;
            }
        }

        .slick-dots {
            display: flex;
            justify-content: center;
            
            margin: 0;
            padding: 5px 0;
            
            list-style-type: none;
            
                li {
                    margin: 0 0.25rem;
                }
            
                button {
                    
                    display: block;
                    width: 5px;
                    height: 5px;
                    padding: 0;
                    
                    border: none;
                    border-radius: 100%;
                    background-color: rgba(255,255,255,0.7);
                    
                    text-indent: -9999px;
                }
            
                li.slick-active button {
                    background-color: #0a0a0a;
                }
            
        }
    }
}

.charcutiers-cover {
    @extend %cover-base;
    background-image: url('/img/histoire_cover.jpg');
    justify-content: left;

    background-position-y: 8%;

    @include media-breakpoint-down(sm) {
        background-position-y: 30%;
        background-size: auto 138%;
        background-image: url('/img/histoire_cover_mobile.jpg');
        justify-content: center;
    }

    @media only screen and (min-width: 1600px) {
        height: 500px;
    }

    .text-holder {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 40px;
        padding-left: 100px;

        @media only screen and (min-width: 1600px) {
            padding-left: 15%;
        }

        @include media-breakpoint-down(lg) {
            padding-left: 60px;
        }

        @include media-breakpoint-down(md) {
            padding-left: 40px;
        }

        color: #FFF;

        .attached-letters {
            font-family: 'Heritage';
            font-size: 45px;
            margin-bottom: -15px;
        }

        .smaller-text {
            font-family: 'AcherusGrotesque';
            text-transform: uppercase;
            font-size: 18px;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-size: 50px;
        }

        hr {
            width: 20px;
            margin: 0 auto;
            background-color: #FFF;
            height: 2px;
            margin-bottom: 20px;
        }
    }
}

.instagram-widget {
    background-color: #f2f2f2;
    padding: 60px 20px;

    .text-holder {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #000;

        .attached-letters {
            font-family: 'Heritage';
            font-size: 40px;
            margin-bottom: -20px;
        }

        .main-text {
            font-family: 'OdudoSlab';
            text-transform: uppercase;
            font-weight: bold;
            font-size: 50px;
        }

        hr {
            width: 20px;
            background-color: #000;
            height: 2px;
            margin-left: 0 auto;
        }
    }

    overflow: hidden;
    max-width: 100%;

    iframe {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
        filter: gray; /* IE6-9 */
	    -webkit-filter: grayscale(99%); /* Chrome 19+ & Safari 6+ */
        -webkit-backface-visibility: hidden;  /* Fix for transition flickering */
        overflow:hidden;
    }
}